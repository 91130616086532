import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCovkD8huHl5HJLM2sKZKoZrvpM_uV25KE",
  authDomain: "ppurlshort.firebaseapp.com",
  databaseURL: "https://ppurlshort.firebaseio.com",
  projectId: "ppurlshort",
  storageBucket: "ppurlshort.appspot.com",
  messagingSenderId: "936677135805",
  appId: "1:936677135805:web:c60aac60a7e9b1854e2ed4"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;
